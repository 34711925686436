import { useState } from 'react';

function usePagination() {
  const [total, setTotal] = useState(0);

  function setPagination(limit, count) {
    // const totalPagination = count / limit;

    // if (totalPagination > 1) {
    //   const totalRounding = Math.ceil(totalPagination)
    //   return setTotal(totalRounding);
    // }

    // return setTotal(1);
    setTotal(count)

  }

  return [total, setPagination];
}

export default usePagination;