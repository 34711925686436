import React,{useState, useEffect} from 'react';
import AdminHeader from 'components/header/AdminHeader';
import Person from 'assets/person.png';
import Person1 from 'assets/person1.png';
import Setting from 'assets/settings.png';
import SettingRound from 'assets/Round.png';
import classNames from 'classnames';
import services from './services';
import Salesperson from './Salesperson';
import RolesPermission from './RolesPermission';
import AdminView from './AdminView';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Cookie from 'js-cookie'

function Admin() {
  const [isOpen, setIsOpen] = useState(true);

  function toggleOpen() {
    setIsOpen(prev => !prev);
  }

  const [selected, setSelected] = useState(0);
  const [detailResponse, setResponse] = useState({
    data: [],
    total: 0
  });
  const [rolesAndPermission, setRolesAndPermission] = useState(false)
  const [detailRole, setRole] = useState({data: []});
  const [params, setParams] = useState({
    role_id: 0,
    id: 0,
  })
  const [userManagement] = useState([
    {
      id: 2,
      name: 'All User'
    },
    {
      id: 1,
      name: 'Admin'
    },
  ])

  const id = params?.role_id;

  useEffect(() => {
    services.getUser({id}, {
      success: (response) => {
        setResponse(prev => ({ ...prev, data: response }));
      }, error: (err) => {
        console.log(err)
        if (err.message === "Request failed with status code 401") {
          Cookie.remove('SID_OR');
          Cookie.remove('SID_OR_PERMISSIONS');
          Cookie.remove('SID_OR_USER_TYPE');
          window.location.replace('/login');
        }
      }
    });

    services.getRole({
      success: (response) => {
        setRole(prev => ({ ...prev, data: response }));
      }, error: (err) => {
        console.log(err)
        if (err.message === "Request failed with status code 401") {
          Cookie.remove('SID_OR');
          Cookie.remove('SID_OR_PERMISSIONS');
          Cookie.remove('SID_OR_USER_TYPE');
          window.location.replace('/login');
        }
      }
    })
  }, [params]);

  const userSetting = ("font-custom font-semibold text-base")
  // console.log(detailResponse.data.data);
  // console.log(detailRole?.data?.data);
  return (
    <div className="bg-home-bg h-screen w-full overflow-auto">
      <div style={{boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.15)'}}>
        <AdminHeader />
      </div>
      <div className="flex h-full">
        <div className="h-full bg-white hidden lg:block lg:w-3/12 xl:w-2/12 md:w-4/12 w-10/12 pt-8">
          <div className="font-custom font-normal text-sm md:pl-6 pl-2" style={{letterSpacing: '0.05em', fontVariant: 'all-small-caps'}}>
            USER MANAGEMENT
          </div>
          <div className="pb-7 border-b border-solid border-filter-border w-full">
            {userManagement?.map((user) => {
              return(
                <button className={(selected === user.id && rolesAndPermission === false) ? "mt-3 border-l-2 border-solid border-border-color flex items-center md:pl-6" : "mt-3 flex items-center border-l-2 border-solid border-white md:pl-6"}
                  onClick={() => {
                    setSelected(user.id);
                    setParams(prev => ({ ...prev, role_id: user.id}));
                    setRolesAndPermission(false)
                  }}
                  key={user.id}
                >
                  <img src={(selected === user.id && rolesAndPermission === false) ? Person : Person1} className="mr-2" alt="" />
                  <div className={classNames((selected === user.id && rolesAndPermission === false) ? classNames(userSetting,'text-black-color') : (userSetting, 'text-date-range'))}>
                    {user.name}
                  </div>
                </button>
              )}
            )}
          </div>
          <button className="flex lg:pl-7 pt-7 items-center" onClick={() => setRolesAndPermission(true)}>
            <img src={rolesAndPermission === false ? Setting : SettingRound} alt="" className={rolesAndPermission === false ? "md:h-auto lg:h-5 mr-2" : "md:h-auto w-4 mr-2"} />
            <div className={rolesAndPermission === false ? "font-custom font-semibold text-base text-date-range pr-1" : "font-custom font-semibold text-base text-black-color pl-1"}>
              Permission
            </div>
          </button>
        </div>
        <div className="w-full overflow-x-auto">
          {(rolesAndPermission === false && (params.role_id === 3 || params.role_id === 2)) && (
            <Salesperson 
              id={params.role_id}
            />
          )}
          {(rolesAndPermission === false && (params.role_id === 1)) && (
            <AdminView
              id={params.role_id}
              name={params.role_id === 3 ? 'Salesperson' : 'Manager'}
            />
          )}
          {(rolesAndPermission === true) && (
            <RolesPermission 
              rolesAndPermission={rolesAndPermission}
            />
          )}
        </div>
      </div>
      <SwipeableDrawer
        className="flex bg-white lg:hidden"
        anchor="left"
        open={isOpen}
        onOpen={toggleOpen}
        onClose={toggleOpen}
      >
        <div className="h-full w-screen bg-white flex flex-col lg:hidden items-center pt-8">
          <div className="font-custom font-normal text-sm md:pl-6" style={{letterSpacing: '0.05em', fontVariant: 'all-small-caps'}}>
            USER MANAGEMENT
          </div>
          <div className="pb-7 pt-3 border-b border-solid border-filter-border w-full">
            {userManagement?.map((user) => {
              return(
                <div className={(selected === user.id && rolesAndPermission === false) ? "border-l-2 border-solid border-border-color flex items-center justify-center" : "items-center flex justify-center"}
                  onClick={() => {
                    setSelected(user.id);
                    setParams(prev => ({ ...prev, role_id: user.id}));
                    setRolesAndPermission(false)
                    toggleOpen()
                  }}
                  key={user.id}
                >
                  <img alt="" src={(selected === user.id && rolesAndPermission === false) ? Person : Person1} className="mr-2" />
                  <div className={classNames((selected === user.id && rolesAndPermission === false) ? (userSetting,'text-black-color') : (userSetting, 'text-date-range'))}>
                    {user.name}
                  </div>
                </div>
              )}
            )}
          </div>
          <div className="flex lg:pl-7 pt-7 items-center" onClick={() => {
            setRolesAndPermission(true);
            toggleOpen();
          }}>
            <img alt="" src={Setting} className="h-5 md:h-auto" />
            <div className="font-custom font-semibold text-base text-date-range pl-1">
              Permission
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default Admin
