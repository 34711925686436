import React, {Component} from 'react';
import Logo from 'assets/openroadsplash.png'

function LoadingMessage() {
  return (
    <div className="lg:hidden bg-login-background bg-cover bg-no-repeat">
      <div className="min-h-screen flex flex-col relative" style={{backgroundImage: 'linear-gradient(180deg, rgba(82, 108, 139, 0.4) 0%, #2E496A 100%)'}}>
        <div className="absolute top-12 -left-14">
          <img src={Logo} alt="" />
        </div>
        <div className="absolute bottom-36 left-6 w-7/12">
          <div className="text-white font-custom font-semibold text-3xl">
            Get to know your customer
          </div>
        </div>
      </div>
    </div>
  );
}

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      // try {
      //   await auth0Client.loadSession();
      //   setTimeout(() => {
      //     this.setState({
      //       loading: false,
      //     });
      //   }, 1500)
      // } catch (err) {
      //   console.log(err);
      //   this.setState({
      //     loading: false,
      //   });
      // }
      setTimeout(() => {
        this.setState({
          loading: false,
        });
      }, 2500)
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return LoadingMessage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSplashScreen;