import React,{useState, useEffect, useReducer} from 'react';
import services from 'pages/Admin/services';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';

function AddNewRole(props) {
  const [roles, setRoles] = useState({
    permissions: [],
  });

  const [params, setParams] = useState({});
  const detailResponse = React.useMemo(() => props?.data, [props?.data]);

  useEffect(() => {
    setParams(detailResponse?.permissions?.map((item, prev) => ({
      ...prev, name: item, position: true
    })));

    services.getPermissions({
      success: (response) => {
        setRoles(prev => ({ ...prev, permissions: response }));
      }, error: (err) => {
        console.log(err)
      }
    })

    // if (props?.modalState?.addNewRole === true) {
    //   dispatch({type: 'initial', payload: {
    //     name: initialState,
    //     onSelectedUser: initialState
    //   }})
    // }
  }, [])
  const [selectedPermissions, setSelectedPermission] = useState(null)
  const [names, setNames] = useState(null)

  let name = names;
  let permissions= selectedPermissions;
  const onSaveChanges = () => { 
    return(
      props.closeModal(),
      // props.setStateRerender(),
      services.newRole({
        name,
        permissions,
      }, (response, err) => {
        if (!err) {
          // setSelected(true);
          console.log(response);
          setSelectedPermission(null);
          setNames(null);
        }
      })
    )
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  const handleOnChange = (event, permission) => {
    if (event.target.checked ) {
      setSelectedPermission(prev => prev!==null ? [...prev, event.target.name] : [event.target.name])
    } else {
      setSelectedPermission(prev => { return prev.filter(i => i !== event.target.name) })
    }
  }
  return (
    <div className="xl:h-full overflow-auto">
      <div className="bg-white xl:mb-1">
        <div className="flex md:flex-1 items-center px-4 xl:pb-6 border-b border-solid border-filter-border">
          <div className="xl:py-6 py-3 w-full">
            <div className="font-custom font-semibold text-base text-black-color opacity-80 pb-1">
              Role Name
            </div>
            <input
              className="mt-2 border border-solid box-border border-edit-role flex md:flex-1 font-custom font-semibold text-base text-black-color md:py-3 py-2 pl-4 rounded-lg lg:pr-80 pr-0 opacity-80"
              onChange={(e) => setNames(e.target.value)}
              value={names === null ? 'Enter role name' : names}
              onFocus={(e) => setNames('')}
            />
          </div>
        </div>
      </div>

      <div className="flex md:flex-1 flex-col items-center px-4 pt-2 xl:pb-6 xl:pt-6 border-b border-solid border-filter-border">
        <div className="font-custom self-start font-semibold text-base text-black-color opacity-80 xl:pb-4">
          Permission
        </div>
        {roles?.permissions?.data?.map((data,index) => {
          const isChecked = selectedPermissions?.indexOf(data) >= 0;
          return(
            <div key={index} className="ml-4 w-full flex items-center">
              <Box sx={{
                '& .MuiIconButton-label': {color: '#2E496A'}}}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    color="#2E496A"
                    control={
                      <Checkbox checked={isChecked} name={data} onChange={handleOnChange} color="default"
                        className="font-custom font-normal text-sm text-black-color"
                      />
                    }
                    label={titleCase(data)}
                  />
                </FormGroup>
              </FormControl>
              </Box>
            </div>
          )
        })}
      </div>
      <div className="flex justify-between xl:pt-6 pt-2">
        <div></div>
        <div className="flex">
          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg xl:py-3 xl:px-16 px-12 py-2 bg-border-color text-white mx-auto" onClick={onSaveChanges}>Add Role</button>
        </div>
      </div>
    </div>
  )
}

export default AddNewRole;
