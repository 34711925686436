import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
// import IconCloseOutlined from '@material-ui/icons/CloseOutlined';
// import classNames from 'classNames';
import SearchButton from 'assets/search.png';
import ModalSearch from './ModalSearch';
import classNames from 'classnames';


function Search(props) {
  const {
    autoCompleteIndicator,
    onAutoCompleteClick,
    className,
    changeIndicator,
    options,
    onSearch,
    setSearch,
    classContainer,
    name,
    onClose,
    text,
    value,
    listKeyword: memoizedKeyword,
    isLoad,
    visible,
    ...other 
  } = props;

  // function handleClose() {
  //   onClose();
  // }
  const listKeyword = React.useMemo(() => memoizedKeyword, [memoizedKeyword]);
  return (
    <div className={classNames('inline relative', classContainer)}>
      <input
        name={name}
        className={classNames('w-full rounded-lg bg-white text-sm text-dark-grey px-4 py-3 border border-gray-400 focus:border-primary-blue', className)}
        value={text}
        {...other}
      />
      <button className={visible ? "absolute right-0 top-0 my-auto mr-1 w-10 h-10 flex items-center justify-center focus:outline-none" : "absolute right-0 bottom-1/2 top-1/2 my-auto mr-1 w-10 h-10 flex items-center justify-center focus:outline-none"} type="submit"
        onClick={() => onAutoCompleteClick()}
      >
        <img src={SearchButton} alt="" />
      </button>
      <ModalSearch
        dataSource={listKeyword}
        isLoad={isLoad}
        textInput={onSearch}
        setSearch={setSearch}
        visible={visible}
        onClose={onClose}
        onAutoCompleteClick={onAutoCompleteClick}
        isLoading={other?.isLoading}
      />
    </div>
  );
}

Search.propTypes = {
  classContainer: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
};

Search.defaultProps = {
  onClose: () => {},
};

export default Search;
