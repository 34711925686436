import React, {useState} from 'react'
import PropTypes from 'prop-types';
// import styles from './filterButton.module.css';
// import clsx from 'clsx';
import classNames from 'classnames';
import spikedCorner from 'assets/Beak.png';
// import { Select as MaterialSelect} from '@material-ui/core';

function Filtering(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const onOptionClicked = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const { 
    className,
    items,
    onChange,
    options,
    placeholderName,
    imageIcon,
    dateRange,
    imageClassName,
    searchButton,
    filterBadge,
    dateCustom,
    homeFilter,
    onImageOpen,
    ...other
  } = props;

  const toggling = () => setIsOpen({open: onImageOpen});
 
  const onOpen = classNames(
    {
      'transform rotate-180': onImageOpen,
    }
  )

  const filterButton = classNames(
    {
      'bg-error rounded-xl px-2 pt-0.5 font-custom font-semibold text-xs text-white': filterBadge,
    }
  )
  return (
    <div className={classNames("flex flex-col font-custom cursor-pointer")}>
      <div className={homeFilter ? "text-xxs leading-1 text-white" : "text-xxs leading-1" }>
        {placeholderName}
      </div>
      <div className={"w-max relative text-sm font-custom font-semibold"} onChange={onChange} {...other}>
        {(dateCustom ?? filterBadge) ?
          <div className={classNames(className,"flex flex-row justify-between mb-2 pr-2")} onClick={toggling}>
            {filterBadge ? <div className="pr-1">{options}</div> : null}
            <div className={filterButton} value="">
              {dateCustom ?? (`0${filterBadge}`)}
            </div>
            {filterBadge ? null : imageIcon &&
              <img src={imageIcon} alt="" className={dateRange ? ("ml-1 w-3 md:w-4 md:h-5") : classNames(onOpen,"w-5")} />
            }
          </div>
          : selectedOption === null ?
          <div className={classNames(className,"flex flex-row justify-between mb-2 pr-2")} onClick={toggling}>
            <div className="" value="">
              {options}
            </div>
            {imageIcon &&
              <img src={imageIcon} alt="" className={dateRange ? classNames(imageClassName,"ml-1 lg:ml-2 w-4 h-5") : classNames(onOpen,"w-3 h-4 lg:ml-1 md:h-5 md:w-5")} />
            }
          </div>
          : 
          <div className={classNames(className,"flex flex-row justify-between mb-2")} onClick={toggling}>
            <div className="" value="">
              {selectedOption}
            </div>
            {imageIcon &&
              <img src={imageIcon} alt="" className={dateRange ? ("") : classNames(onOpen,"w-5")} />
            }
          </div>
        }
      </div>
    </div>
  );
}

Filtering.propTypes = {
  className: PropTypes.string,
  items: PropTypes.any,
  options: PropTypes.string,
  onChange: PropTypes.func,
};

export default Filtering