import React,{useState} from 'react';
import classNames from 'classnames';

function FilterDealershipModal(props) {

  const {
    // activitySelected,
    // setActivitySelected,
    dealershipSelected,
    setDealershipSelected,
  } = props

  const buttonClass = classNames(
    'border border-solid border-border-color rounded-3xl px-3 py-1 mr-2 font-semibold text-sm'
  )

  const filterReset = () => {
    // setActivitySelected([null]);
    setDealershipSelected([null]);
    props.onFilterBadge(0);
    // props.setFilterType([]);
    props.setFilterDealership([]);
    props?.setOptionsFilter([]);
  }

  const propsfilterOptions = name => {
    if (name === "All Dealership" ) {
      props?.setOptionsFilter(([name]))
    } else if (props?.optionsFilter?.includes(name)) {
      props?.setOptionsFilter(prevSelected => prevSelected?.filter(function (str) { return str?.indexOf(name) === -1; }));
    } else {
      props?.setOptionsFilter(prevSelected => ([...prevSelected, name]));
      props?.setOptionsFilter(prevSelected => (prevSelected?.filter(function (str) { return str?.indexOf("All") === -1; })));
    }
  }

  const propsDealershipSelected = name => {
    if ( name === "All" ) {
      props?.setFilterDealership(([name]));
      props?.onFilterBadge((props.filterType.length !== 0 && (props?.filterDealership?.includes?.("All") === false)) ? props?.filterType?.length+1 : (props.filterType.length !== 0 && (props?.filterDealership?.includes?.("All") === true)) ? props?.filterType?.length+1 : 1)
    } else if (props?.filterDealership?.includes(name)) {
      props?.setFilterDealership(prevSelected => prevSelected?.filter((str) => str?.indexOf(name) === -1));
      props?.filterBadge === 0 ? props.onFilterBadge(1) : props.onFilterBadge(props.filterBadge-1);
    } else { 
      props?.setFilterDealership(prevSelected => ([...prevSelected, name]));
      props?.setFilterDealership(prevSelected => (prevSelected?.filter(function (str) { return str?.indexOf("All") === -1; })));
      props?.onFilterBadge(props?.filterDealership.includes("All") ? props?.filterType?.length+1 : props?.filterBadge+1);
      // props?.setFilterDealership(prevSelected => (prevSelected?.filter((s) => s !== "All")));
    }
  };
  // console.log(props.filterDealership)
  const handleDealershipSelected = (id) => {
    if (id === 0 ) {
      setDealershipSelected([0])
    } else if (dealershipSelected.includes(id)) {
      setDealershipSelected(prevSelected => prevSelected.filter((s) => s !== id && s !== 0));
    } else { 
      setDealershipSelected(prevSelected => ([...prevSelected, id]));
      setDealershipSelected(prevSelected => prevSelected.filter((s) => s !== 0));
    }
  };

  return (
    <div className="overflow-auto">
      {/* <div className="bg-white mb-1 pl-4 md:pl-0 border-b border-solid border-filter-border">
        <div className="font-semibold text-base pt-4 pb-4 bg-white">
          Activity
        </div>

        <div className="w-full">
          {listActivity.map((activity,index) => { return(
            <button className={activitySelected?.indexOf(index) > -1 ? classNames(buttonClass, "mb-4 bg-border-color text-white") : classNames(buttonClass, "mb-4 text-border-color")} key={index} value={activity}
              onClick={(e) => {
                  handleSelected(index)
                  propsFilterSelected(activity.name)
                  propsfilterOptions(activity.name)
                }}>
                {activity.name}
            </button>
          )})}
        </div>
      </div> */}

      <div className="bg-white mb-1 pl-4 md:pl-0 border-b border-solid border-center-modal-border">
        <div className="font-semibold text-base pt-4 pb-4 bg-white">
          Dealership
        </div>

        <div className="w-11/12 md:w-full">
          {[{ id: null, dealership_name: 'All Dealership', dealership_code: "All" }, ...props?.listDealership]?.map((dealer,index) => { return(
            <button className={(dealershipSelected).indexOf(index) > -1 ? classNames(buttonClass, "mb-4 bg-border-color text-white") : classNames(buttonClass, "mb-4 text-border-color")} key={index} value={dealer.dealership_code}
              onClick={(e) => {
                  handleDealershipSelected(index)
                  propsDealershipSelected(dealer.dealership_code)
                  propsfilterOptions(dealer.dealership_name)
                }}>
                {dealer.dealership_name}
            </button>
          )})}
        </div>
      </div>

      {(!(props?.listDealership?.length>3)) && <div className="bg-white pt-2 pb-8 md:hidden flex">
        <div className="flex justify-between px-2">
          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 xxxs:px-9 xxs:px-12 xs:px-16 px-11 text-border-color" onClick={() => filterReset()}>Reset Filter</button>

          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 xxxs:px-11 xxs:px-14 xs:px-16 px-11 bg-border-color text-white ml-4" onClick={props.closeModal}>Show</button>
        </div>
      </div>
      }
      {(!(props?.listDealership?.length>3)) && <div className="bg-white pt-2 pb-8 hidden md:flex">
        <div className="flex justify-between px-2">
          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-10 lg:px-16 text-border-color" onClick={() => filterReset()}>Reset Filter</button>

          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-14 lg:px-20 bg-border-color text-white ml-4" onClick={props.closeModal}>Show</button>
        </div>
      </div>
      }
      {props?.listDealership?.length>3 && <div className="bg-white pt-2 pb-8 md:hidden flex mb-10">
        <div className="flex justify-between px-2 xs:pl-4">
        <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 xxxs:px-9 xxs:px-11 xs:px-14 px-11 text-border-color" onClick={() => filterReset()}>Reset Filter</button>

        <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 xxxs:px-11 xxs:px-16 xs:px-18 px-16 bg-border-color text-white ml-4" onClick={props.closeModal}>Show</button>
        </div>
      </div>}

      {props?.listDealership?.length>3 && <div className="bg-white pt-2 pb-8 hidden md:flex">
        <div className="flex justify-between px-2">
        <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-10 lg:px-16 xl:px-30 text-border-color" onClick={() => filterReset()}>Reset Filter</button>

        <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-14 lg:px-24 xl:px-36 bg-border-color text-white ml-4" onClick={props.closeModal}>Show</button>
        </div>
      </div>}
      
    </div>
  )
}

export default FilterDealershipModal
