import React, {useState, useEffect} from 'react'
import Card from 'components/card'
import services from '../services';
import { Bar } from 'react-chartjs-2';
import CircularProgress from "@material-ui/core/CircularProgress";
import Cookie from 'js-cookie'

function Insight(props) {
  const [isLoading, setIsLoading] = useState(true);

  const orag_id = props.id
  const [listResults, setlistResults] = useState({})
  useEffect(() => {
    services.getTransactionInsight({orag_id}, (response, err) => {
      if (!err) {
        setlistResults(response);
        setIsLoading(false)
      } else {
        if (err.message === "Request failed with status code 401") {
          Cookie.remove('SID_OR');
          Cookie.remove('SID_OR_PERMISSIONS');
          Cookie.remove('SID_OR_USER_TYPE');
          window.location.replace('/login');
        }
      }
    });
  }, [orag_id]);

  const data = (canvas) => {
    const g = '#00AEEF'
    return {
        labels: canvas?.keys?.map((key) => key),
        datasets: [{
          data: canvas?.value?.map((value) => value),
          backgroundColor: g,
          borderColor: g,
          borderWidth: 1,
        }],
    };
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
          display: false,
      },
    }
  };

  return (
    <>
    {isLoading && (
      <div className="flex w-full py-5 justify-center">
        <CircularProgress color="black" size={20} />
      </div>
    )}
    <div className="lg:flex lg:w-11/12 mx-auto">
      <div className="lg:w-1/3">
        <Card childrenName={'Vehicle Purchase'}>
          <div className="bg-white mt-4 flex flex-col mx-auto font-semibold text-sm">
            {listResults?.data?.vehicle_type?.title}
            <Bar data={data(listResults?.data?.vehicle_type?.data)} options={options} />
          </div>
          <div className="flex flex-row justify-between my-4">
            <div className="font-semibold text-sm">
              Total Units
              <div className="font-normal">{listResults?.data?.vehicle_type?.total}</div>
            </div>
            <div className="font-semibold text-sm">
              Total Spending
              <div className="font-normal">$ {listResults?.data?.vehicle_type?.total_purchase}</div>
            </div>
          </div>
          <div className="font-semibold text-sm mb-4">
            Time to Last Purchase
            <div className="font-normal">{listResults?.data?.vehicle_type?.time}</div>
          </div>
        </Card>
      </div>

      <div className="lg:w-1/3">
        <Card childrenName={'Service'}>
          <div className="bg-white mt-4 flex flex-col mx-auto font-semibold text-sm">
            {listResults?.data?.service?.title}
            <Bar data={data(listResults?.data?.service?.data)} options={options} />
          </div>
          <div className="flex flex-row justify-between my-4">
            <div className="font-semibold text-sm">
              Total Services
              <div className="font-normal">{listResults?.data?.service?.total}</div>
            </div>
            <div className="font-semibold text-sm">
              Total Spending
              <div className="font-normal">$ {listResults?.data?.service?.total_purchase}</div>
            </div>
          </div>
          <div className="font-semibold text-sm mb-4">
            Time to Last Service
            <div className="font-normal">{listResults?.data?.service?.time}</div>
          </div>
        </Card>
      </div>

      <div className="pb-20 lg:w-1/3">
        <Card childrenName={'Parts'}>
          <div className="bg-white mt-4 flex flex-col mx-auto font-semibold text-sm">
            {listResults?.data?.parts?.title}
            <Bar data={data(listResults?.data?.parts?.data)} options={options} />
          </div>
          <div className="flex flex-row justify-between my-4">
            <div className="font-semibold text-sm">
              Total Units
              <div className="font-normal">{listResults?.data?.parts?.total}</div>
            </div>
            <div className="font-semibold text-sm">
              Total Spending
              <div className="font-normal">$ {listResults?.data?.parts?.total_purchase}</div>
            </div>
          </div>
          <div className="font-semibold text-sm mb-4">
            Time to Last Purchase
            <div className="font-normal">{listResults?.data?.parts?.time}</div>
          </div>
        </Card>
      </div>
    </div>
    </>
  )
}

export default Insight
