import React,{useState} from 'react';
import classNames from 'classnames';

function SortModal(props) {
  const {sortSelected, setSortSelected} = props;
  const [sortValue, setSortValue] = useState(null);

  const [listSort] = useState([{name: 'Latest', value: 'latest'}, {name: 'Oldest', value: 'oldest'}]);
  
  const buttonClass = classNames(
    'border border-solid border-border-color rounded-3xl px-3 py-1 mr-2 font-semibold text-sm'
  )

  const filterReset = () => {
    setSortSelected(0);
    console.log('FilterReset');
    props.setSortValue(null);
  }
  return (
    <>
      <div className="overflow-auto">
        <div className="bg-white mb-1">
          <div className="w-11/12 pt-9 md:pl-2 pl-4">
            {listSort.map((sort,index) => (
              <button className={sortSelected === (index) ? classNames(buttonClass, "mb-4 bg-border-color text-white") : classNames(buttonClass, "mb-4 text-border-color")} key={index} value={sort.value}
                onClick={(e) => {
                    setSortSelected(index);
                    props.setSortValue(e.target.value);
                  }}>
                  {sort.name}
              </button>
            ))}
          </div>
          <div className="pt-5 border-b border-solid border-center-modal-border w-full" />
        </div>
        <div className="bg-white pt-4 pb-9 md:hidden flex">
          <div className="flex justify-between ml-2 md:ml-0 px-2">
            <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-8 xxs:px-12 xs:px-14 lg:px-16 text-border-color" onClick={() => filterReset()}>Reset Filter</button>

            <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-12 xxs:px-14 xs:px-18 lg:px-20 ml-2 bg-border-color text-white" onClick={props.closeModal}>Show</button>
          </div>
        </div>
        <div className="bg-white pt-4 pb-9 hidden md:flex">
          <div className="flex justify-between ml-2 md:ml-0 px-2">
            <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-8 xxs:px-12 xs:px-8 lg:px-16 text-border-color" onClick={() => filterReset()}>Reset Filter</button>

            <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-12 xxs:px-14 xs:px-12 lg:px-20 ml-2 bg-border-color text-white" onClick={props.closeModal}>Show</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SortModal
