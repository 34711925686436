import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CloseIcon from 'assets/close.png';
import classNames from 'classnames';

const Backdrop = (props) =>
  props.show ? (
    <div className="w-full h-full fixed z-10 left-0 top-0 bg-black bg-opacity-60" onClick={props.clicked}></div>
  ) : null;

Backdrop.propTypes = {
  clicked: PropTypes.func,
  show: PropTypes.bool,
};

const Modal = (props) => {
  const {children} = props;
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.closeModal} />
      <div
        className={classNames("fixed overflow-y-auto left-0 z-20 top-0 right-0 bottom-0 w-full bg-profile-bg border border-solid box-border transition-all flex md:hidden flex-col", props?.heightStyle)}
        style={ props.onSortModal ? {
          transform: props.show ? "translateY(60%)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        } : props.onDealershipModal ? {
          transform: props.show ? "translateY(20%)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        } : props.onFilterModal ? {
          transform: props.show ? "translateY(23%)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        } : props.onDateModal ? {
          transform: props.show ? "translateY(25%)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        } : {
          transform: props.show ? "translateY(10rem)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        }
      }
      >
        <div className="flex-row flex justify-between pb-4 mb-1 pt-5 px-4 w-full bg-white">
          <div className="font-semibold text-black-color text-xl">{props?.title}</div>
          <img src={CloseIcon} alt="" onClick={props.closeModal} />
        </div>
        <div>{children}</div>
      </div>
    </Fragment>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.element,
};

const CenterModal = (props) => {
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.closeModal} />
      <div
        className={props.adminModal === false ? "fixed hidden z-20 md:flex flex-col top-1/2 left-1/2 bg-white border border-solid border-center-modal-border box-border rounded-xl px-4 py-6" : "fixed hidden z-20 md:flex flex-col top-1/2 left-1/2 bg-white border border-solid border-center-modal-border box-border rounded-xl px-4 pb-4"}
        style={{
          transform: props.show ? "translate(-50%, -50%)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        }}
      >
        <div
          className={props.adminModal === false ? 'flex-row flex justify-between pb-4 mb-1 pt-5 px-4 w-full bg-white' : 'flex-row flex justify-between pb-4 mb-1 pt-4 w-full bg-white border-b border-solid border-filter-border'}
        >
          <div className={props.adminModal ? "font-custom font-semibold text-black-color text-base" : "font-semibold text-black-color text-xl"}>
            {props.title}
          </div>
          <img src={CloseIcon} alt="" onClick={props.closeModal} />
        </div>
        {props.children}
      </div>
    </Fragment>
  );
};

CenterModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  children: PropTypes.any,
};

const CenterModalAdmin = (props) => {
  return (
    <Fragment>
      <Backdrop show={props.show} clicked={props.closeModal} />
      <div
        className={props.adminModal === false ? "fixed z-20 flex flex-col top-1/2 left-1/2 bg-white border border-solid border-center-modal-border box-border rounded-xl px-4 py-6" : "fixed z-20 flex flex-col top-1/2 left-1/2 bg-white border border-solid border-center-modal-border box-border rounded-xl px-4 pb-4"}
        style={{
          transform: props.show ? "translate(-50%, -50%)" : "translateY(100vh)",
          opacity: props.show ? 1 : 0,
        }}
      >
        <div
          className={props.adminModal === false ? 'flex-row flex justify-between pb-4 mb-1 pt-5 px-4 w-full bg-white' : props.adminAddNewModal ? 'flex-row flex justify-between pb-4 mb-1 pt-4 px-2 w-full bg-white' : props.deleteRoleModal ? 'hidden' : 'flex-row flex justify-between pb-4 mb-1 pt-4 px-4 w-full bg-white border-b border-solid border-filter-border'}
        >
          <div className={props.adminModal ? "font-custom font-semibold text-black-color text-base" : "font-semibold text-black-color text-xl"}>
            {props.title}
          </div>
          <img src={CloseIcon} alt="" onClick={props.closeModal} />
        </div>
        {props.deleteRoleModal && (
          <div className="self-end pt-4 absolute">
            <img src={CloseIcon} alt="" onClick={props.closeModal} />
          </div>
        )}
        {props.children}
      </div>
    </Fragment>
  );
};

CenterModalAdmin.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  children: PropTypes.any,
};

export {Modal, CenterModal, CenterModalAdmin};
