import React from 'react';
import Card from 'components/card';

function ProfileDetail(props) {

  const {detailResponse: detailItems} = props;
  const detailResponse = React.useMemo(() => detailItems, [detailItems]);
  // console.log(detailResponse?.data?.club_info?.detail_points[0])
  return (
    <div className="lg:flex-row lg:flex">
      <div className="lg:w-1/2">
        <Card childrenName={'Personal Information'}>
          <div className="flex flex-col">
            <div className="text-sm font-semibold">
              Email
              <div className="font-normal	">
                {detailResponse?.data?.email}
              </div>
            </div>
            <div className="text-sm font-semibold py-4">
              Phone Number
              <div className="font-normal	w-28">
                {detailResponse?.data?.homephone}
              </div>
            </div>
            <div className="text-sm font-semibold pb-4">
              Addresss
              <div className="font-normal w-7/12">
                {detailResponse?.data?.address}
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="pb-32 lg:w-1/2">
        <Card childrenName={'Club OpenRoad'}>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between text-sm font-semibold">
              <div>
                Club OpenRoad ID
                <div className="flex flex-col font-normal">
                  {detailResponse?.data?.club_info?.detail_points?.map(data => (
                    <div key={detailResponse?.data?.id}>
                      {data?.cor_id}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                Available Points
                <div className="font-normal flex flex-col">
                  {detailResponse?.data?.club_info?.detail_points?.map(data => 
                    <div key={detailResponse?.data?.id}>
                      {data?.available_point} pts
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="text-sm font-semibold py-4">
              Total Redemptions
              <div className="font-normal	w-28">
                {detailResponse?.data?.club_info?.total_redemption} pts
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default ProfileDetail
