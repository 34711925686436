import React, {useEffect, useState, useContext} from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import services from './services';
import token from 'utils/session';
import {AdminContext} from 'utils/context';
import Cookie from 'js-cookie';

function AzureLogin() {
  const [isError, setIsError] = useState(false)
  const { thisState, updateInfo } = useContext(AdminContext);

  useEffect(() => {
    services.loginAzure({
      success: (succ) => {
        updateInfo(succ.data.permissions);
        console.log(succ);
        if ((succ.data.role === "Salesperson" || succ.data.role === "Manager") && thisState.permissions !== null) {
          window.location.replace('/');
        } else {
          window.location.replace('/admin');
        }
      },
      error: (err) => {
        setIsError(!isError);
        console.log(err)
      }
    })
  }, [])

  const classLogo = classnames('flex-col items-center justify-center', 
  {
    hidden: isError,
    flex: !isError,
  }
  );

  const classError = classnames('flex-col items-center justify-center', 
  {
    hidden: !isError,
    flex: isError,
  }
  );

  return (
    <div>
      <div className="min-h-screen w-full flex items-center justify-center flex-col">
        <div className={classLogo}>

          <div className="mt-5">
          </div>
        </div>

        <div className={classError}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-20 w-20 text-danger"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <span className="font-custom text-2xl text-black font-semibold">
            Cannot Login!
          </span>
          <p className="font-custom text-xl text-black mt-2">
            User Not Registered!
          </p>
          <div className="flex flex-col">
            <Link
              to="/login"
              className="font-custom bg-primary px-4 py-2 rounded-sm text-sm font-semibold text-black mt-5"
            >
              Back to Login Page
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AzureLogin
