import React,{useState, useEffect} from 'react';
import classNames from 'classnames';
import SearchButton from 'assets/search.png';
import services from 'pages/Admin/services';
import CloseIcon from 'assets/close.png';
import { data } from 'autoprefixer';

function AddNewAdmin(props) {
  const [params, setParams] = useState({
    q: null,
    page: 1,
    limit: 4,
    visible: false,
    onTextSearch: false,
    focus: false,
    onSelectRole: 0,
  })

  const [detailResponse, setResponse] = useState({
    data: [],
  })

  const [onSelectedUser, setOnSelectedUser] = useState([])

  useEffect(() => {
    if (params.q === '' || params.q === null) {
      setResponse(prev => ({ ...prev, data: [] }));
    } else {
      services.getUserSearch({q: params.q}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
        }, error: (err) => {
          console.log(err)
        }
      });
    }
  }, [params])

  const onUserInput = (e) => {
    const textSearch = e.target.value;
    setParams(prev => ({ ...prev, q: textSearch}))

    if (params.q !== null) {
      setParams(prev => ({ ...prev, visible: true}));
    }

    if (textSearch === '') {
      setParams(prev => ({ ...prev, visible: false}))
    }

    clearTimeout(window.timeout);

    window.timeout = setTimeout(() => {
      setParams(prev => ({ ...prev, onTextSearch: textSearch}));
    }, 1000);
  }

  function onEnter(e) {
    if (e.key === 'Enter') {
      setParams(prev => ({ ...prev, visible: false}))
    }
  }

  const id = onSelectedUser?.map(data => (
    data.id
  ))

  const onAddAdmin = () => {
    services.updateUserAdmin({
      user_id: id
    }, {
      success: (response) => {
        setResponse(prev => ({ ...prev, data: response.data }));
      }, error: (err) => {
        console.log(err)
      }
    });
    props.closeModal()
  }

  const handleRemoveItem = (e) => {
    setOnSelectedUser(onSelectedUser?.filter(i => i.id !== e));
  };

  const thisSelectedDataID = onSelectedUser?.map(data => (
    data.id
  ))

  return (
    <div className="px-2 overscroll-y-auto">
      <div className="border-t border-solid border-filter-border text-black-color text-base font-semibold font-custom pt-6 pb-2">
        Add User
      </div>
      <div className="bg-white w-full mr-32 flex relative mb-6">
        <input
          name="search"
          className={classNames('w-full rounded-lg font-semibold bg-profile-bg font-custom text-neutral-color text-base pr-64 px-4 py-3 border border-gray-400 focus:border-primary-blue md:mr-0')}
          onChange={(e) => onUserInput(e)}
          value={params.focus ? params.q : "Find User"}
          onKeyDown={e => onEnter(e)}
          onFocus={() => setParams(prev => ({ ...prev,
            focus: true,
            q: "",
          }))}
        />
        <button className={params.visible ? "absolute right-0 top-0 bottom-0 my-auto mr-1 w-10 h-10 flex items-center justify-center focus:outline-none" : "absolute right-0 top-1/2 bottom-1/2 my-auto mr-1 w-10 h-10 flex items-center justify-center focus:outline-none"} type="submit"
          onClick={(e) => onEnter(e)}
        >
          <img src={SearchButton} alt="" />
        </button>
      </div>
      {onSelectedUser?.map((data) => {
        return(
          <div className="pt-2 pb-10 flex flex-1 w-full font-custom border-b border-solid border-center-modal-border" style={{color: '#272833'}} key={data?.id}>
            <div className="flex flex-col flex-1 w-full">
              <div className="flex w-full justify-between text-left font-semibold text-base">
                <div>
                  {data?.fullname}
                </div>
                <button onClick={() => {
                  handleRemoveItem(data?.id);
                }}>
                  <img alt="" src={CloseIcon} />
                </button>
              </div>
              <div className="text-left font-normal text-sm">
                {data?.email}
              </div>
            </div>
          </div>
        )
      })}
      {detailResponse?.data?.map((data) => {
        console.log({thisSelectedDataID});
        return(
          <div className="pt-2 pb-10 flex flex-1 w-full font-custom border-b border-solid border-center-modal-border" style={{color: '#272833'}} key={data?.id}>
            <button className="flex flex-col flex-1 w-full" onClick={() =>
              thisSelectedDataID?.indexOf(data.id) === -1 ?
              setOnSelectedUser(prevState => {
                  return [...prevState, data]
                }
              ) :
              setOnSelectedUser(prevState => {
                return [...prevState]
              })
            }>
              <div className="flex w-full justify-between text-left font-semibold text-base">
                <div>
                  {data?.fullname}
                </div>
              </div>
              <div className="text-left font-normal text-sm">
                {data?.email}
              </div>
            </button>
          </div>
        )
      })}
      <div className="flex justify-end border-t border-solid border-filter-border">
        <div></div>
        <div className="flex">
          <button className={(params.q === "" || params.q === null) ? "mt-6 font-custom font-semibold text-base box-border border-2 border-solid border-filter-border rounded-lg py-3 px-7 bg-date-range text-white mx-auto" : "mt-6 font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-7 bg-border-color text-white mx-auto"} onClick={() => onAddAdmin()}>Add Admin</button>
        </div>
      </div>
    </div>
  )
}

export default AddNewAdmin
