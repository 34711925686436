import PropTypes from 'prop-types';
import { usePagination } from '@material-ui/lab';
import classnames from 'classnames';

function Pagination(props) {
  const { count, page, onChange } = props;
  const { items } = usePagination({
    page,
    onChange,
    count,
  });

  return (
    <ul className={classnames('flex items-center space-x-4')}>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = '...';
        } 
        else if (type === 'page') {
          const classActive = classnames('transition duration-150 font-custom font-semibold focus:outline-none text-base',
          {
            'hidden lg:block text-border-color ease-in': selected,
            'hidden lg:block text-neutral-color ease-out': !selected,
          },
          );
          children = (
            <>
              <button className={classActive} {...item}>
                {page}
              </button>
            </>
          );
        } 
        else if (type === 'previous') {
          const { disabled } = { ...item };
          const classPrev = classnames('items-center flex transition duration-150 ease-in-out focus:outline-none text-base font-custom font-semibold', {
            'text-border-color': !disabled,
            'text-neutral-color': disabled,
          });
          children = (
            <button className={classPrev} type="button" {...item}>
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99969 4.47C9.87513 4.34516 9.70603 4.27501 9.52969 4.27501C9.35334 4.27501 9.18424 4.34516 9.05969 4.47L5.99969 7.53C5.73969 7.79 5.73969 8.21 5.99969 8.47L9.05969 11.53C9.31969 11.79 9.73969 11.79 9.99969 11.53C10.2597 11.27 10.2597 10.85 9.99969 10.59L7.41302 7.99667L9.99969 5.41C10.2597 5.15 10.253 4.72333 9.99969 4.47Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <div className={classPrev}>
                Previous
              </div>
            </button>
          )
        } else if (type === 'next') {
          const { disabled } = { ...item };
          const classNext = classnames('flex items-center transition duration-150 ease-in-out focus:outline-none text-base font-custom font-semibold', {
            'text-border-color': !disabled,
            'text-neutral-color': disabled,
          });
          children = (
            <button className={classNext} type="button" {...item}>
              <div className={classNext}>
                Next
              </div>
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.99969 4.47375C5.73969 4.73375 5.73969 5.15375 5.99969 5.41375L8.58635 8.00042L5.99969 10.5871C5.73969 10.8471 5.73969 11.2671 5.99969 11.5271C6.25969 11.7871 6.67969 11.7871 6.93969 11.5271L9.99969 8.46708C10.2597 8.20708 10.2597 7.78708 9.99969 7.52708L6.93969 4.46708C6.68635 4.21375 6.25969 4.21375 5.99969 4.47375Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          )
        }

        return <li key={index}>{children}</li>
      })}
    </ul>
  );
}

Pagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

Pagination.defaultProps = {
  onChange: () => {},
};

export default Pagination;