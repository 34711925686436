import React, {useReducer, useState} from 'react'

const AdminContext = React.createContext([[],() => {}])
const initialState = {
  roles: [],
  permissions: [],
  sync: true,
}

let itemsReducer = (thisState = initialState, action) => {
  switch(action.type){
  case "ROLES":
    return {
      roles: [...thisState.roles,action.payload.data],
    }
  case "PERMISSIONS":
    return {
      permissions: [...thisState.permissions,action.payload.data],
    }
  case "SYNC":
    return {
      ...thisState,
      sync: action.payload.data,
    }
  default:
    return thisState
  }
}

function AdminProvider(props) {

  const [thisState, dispatch] = useReducer(itemsReducer, initialState);
  const [sortValue, setSortValue] = useState(null);

  const [filterBadge, setFilterBadge] = useState(0);

  const [filterDealershipBadge, setFilterDealershipBadge] = useState(0);

  const [filterType, setFilterType] = useState([])

  const [filterDealership, setFilterDealership] = useState([])

  const [sortSelected, setSortSelected] = useState(0);

  const [activitySelected, setActivitySelected] = useState([]);

  const [dealershipSelected, setDealershipSelected] = useState([]);

  const [optionsFilter, setOptionsFilter] = useState([]);

  const [dealershipFilter, setDealershipFilter] = useState([]);

  const [dateRangeFrom, setDateRangeFrom] = useState(null);

  const [dateRangeTo, setDateRangeTo] = useState(null);

  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(new Date());

  const [dateCustom, setDateCustom] = useState(null);

  const [dateRange, setDateRange] = useState(null);

  const [selected, setSelected] = useState("12months")

  const updateInfo = (permission) => {
    dispatch({
      type: 'PERMISSIONS',
      payload: {
        data: permission,
      }
    }); 
  };
  const updateSync = (sync) => {
    dispatch({
      type: 'SYNC',
      payload: {
        data: sync,
      }
    }); 
  };
  return (
    <AdminContext.Provider
      value={{
        thisState,
        dispatch,
        updateInfo,
        updateSync,
        sortValue,
        filterBadge,
        filterDealershipBadge,
        filterType,
        filterDealership,
        sortSelected,
        activitySelected,
        dealershipSelected,
        dealershipFilter,
        optionsFilter,
        dateRangeFrom,
        dateRangeTo,
        startDate,
        endDate,
        dateCustom,
        dateRange,
        selected,

        setSortValue,
        setFilterBadge,
        setFilterDealershipBadge,
        setFilterType,
        setFilterDealership,
        setSortSelected,
        setActivitySelected,
        setDealershipSelected,
        setDealershipFilter,
        setOptionsFilter,
        setDateRangeFrom,
        setDateRangeTo,
        setStartDate,
        setEndDate,
        setDateCustom,
        setDateRange,
        setSelected,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
}

export {
  AdminContext,
  AdminProvider
}
