import React from 'react'
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import CircularProgress from "@material-ui/core/CircularProgress";

function ModalSearch(props) {
  const {
    onClose,
    visible,
    dataSource: memoizedData,
    onAutoCompleteClick,
    isLoading
  } = props;
  const dataSource = React.useMemo(() => memoizedData, [memoizedData]);

  const classNameContainer = classNames(
    {
      'h-74 visible ease-in h-screen overflow-auto pb-40 lg:pb-1': visible,
      'invisible h-0 ease-out overflow-hidden': !visible,
    },
  );

  return (
    <div className={classNameContainer}>
      <div className="" onClick={() => onClose()}>
      </div>
      {isLoading && (
        <div className="flex w-full py-3 justify-center bg-white box-border rounded-lg mt-1 border-solid border border-white">
          <CircularProgress style={{'color': '#2E496A'}} size={20} />
        </div>
      )}
      <div className="bg-white box-border rounded-lg mt-1 border-solid border border-white" >
        {dataSource?.map((option,index) => {
          const url = `/profile/${option.orag_id}`
          return(
            <Link key={index} to={url} onClick={() => onAutoCompleteClick()}>
              <ul className="pl-6 py-4 border-home-bg border-solid border border-l-0 border-r-0 first:border-t-0 last:border-b-0 font-custom">
                <li className="font-semibold text-sm text-black-color">
                  {option.fullname}
                </li>
                <li className="font-normal text-sm text-neutral-color">
                  {option.email}
                </li>
              </ul>
            </Link>
          )}
        )}
      </div>
    </div>
  )
}

export default ModalSearch
