import React,{useState, useEffect, useContext} from 'react';
import Header from 'components/header';
import Search from 'components/search';
import Filter from 'components/filterButton';
import {Link} from 'react-router-dom';
import expandMore from 'assets/expand more.png';
import services from './services';
import {Modal, CenterModal} from 'components/modal';
import DealershipFilter from 'components/modal/DealershipModal';
import Pagination from 'components/pagination';
import usePagination from 'utils/usePagination';
import { AdminContext } from 'utils/context';
import Cookie from 'js-cookie'
import '../../index.css';
import { debounce } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

function Home(props) {

  const { thisState, updateInfo } = useContext(AdminContext);

  const [listFilterDealership, setListFilterDealership] = useState({
    data: [],
    total: 0
  })

  const [isLoadingSearchResult, setIsLoadingSearchResult] = useState(false);

  const [params, setParams] = useState({
    q: null,
    page: 1,
    limit: 4,
    visible: false,
    onTextSearch: false,
    dealership_code: null,
    dealership_name: null,
    onSearchResult: false
  })

  const [search, setSearch] = useState({
    data: [],
    total: 0
  })

  const [focusInput, setFocusInput] = useState(false)
  const [onSearch, setOnSearch] = useState(false);
  const [onAutoCompleteClick, setOnAutoCompleteClick] = useState(false);
  const [modalState, setModalState] = useState({
    dealershipFilterModal: false,
  });

  const [total, setPagination] = usePagination();

  const [isLoading, setIsLoading] = useState(false);

  // var slideSource = document.getElementById('slideSource');
  // console.log(slideSource);
  const setOpen = () => {
    setFocusInput(!focusInput);
  };

  const dealershipFilterHandler = () => {
    setModalState({
      dealershipFilterModal: !modalState.dealershipFilterModal
    })
  }

  const setAutoCompleteClick = () => {
    setOnAutoCompleteClick(true);
    setOnSearch(false)
  };

  const onUserInput = (e) => {
    const textSearch = e.target.value;
    setParams(prev => ({ ...prev, q: textSearch, page: 1}))
    setIsLoading(true);

    if (params.q !== null) {
      setParams(prev => ({ ...prev, visible: true}));
    }

    if (textSearch === '') {
      setParams(prev => ({ ...prev, visible: false}));
      setIsLoading(false);
    }

    clearTimeout(window.timeout);

    window.timeout = setTimeout(() => {
      setParams(prev => ({ ...prev, onTextSearch: textSearch}));
    }, 1000);
  }

  function onEnter(e) {
    if (e.key === 'Enter') {
      setParams(prev => ({ ...prev, visible: false, onSearchResult: true, page: 1}));
      setOnAutoCompleteClick(true)
    } else if (e.key === 'Escape') {
      setParams(prev => ({ ...prev, visible: false, onSearchResult: false, page: 1}));
      setOnAutoCompleteClick(true);
    }
  }

  useEffect(() => {
    services.getDealership({
      success: (response) => {
        setListFilterDealership(response)
      }, error: (err) => {
        console.log(err)
        if (err.message === "Request failed with status code 401") {
          Cookie.remove('SID_OR');
          Cookie.remove('SID_OR_PERMISSIONS');
          Cookie.remove('SID_OR_USER_TYPE');
          window.location.replace('/login');
        }
      }
    });

    updateInfo(JSON.parse(Cookie.get('SID_OR_PERMISSIONS')));

  },[params]);

  useEffect(() => {
    if(params.q) {
      setIsLoadingSearchResult(true);
      services.getUserSearch({
        q: params.q,
        page: params.page,
        dealership_code: params.dealership_code,
      },{
        success: (response) => {
          setIsLoading(false);
          setIsLoadingSearchResult(false);
          setSearch({data: response})
          setPagination(response.per_page, response.last_page);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    }
  }, [params])
  // Cookie.remove('SID_OR_PERMISSIONS');
  // console.log(thisState);
  return (
    <>
      <div className="overflow-hidden" style={focusInput ? {background: '#e5e5e5'} : {background: 'linear-gradient(180deg, #2E496A 0%, #526C8B 100%)'}}>
        <div className="h-screen lg:overflow-auto">
          <div className="lg:block" style={{background: 'linear-gradient(180deg, #2E496A 0%, #526C8B 100%)'}}>
            <Header/>
            {focusInput === false ? (
              <div className="absolute md:top-1/2 md:bottom-1/2 md:right-1/4 md:left-1/4 inline md:block md:w-7/12 lg:w-7/12 w-10/12 top-1/2 bottom-1/2 right-8">
                <Search
                  placeholder="Search Customer Here"
                  name="search"
                  classContainer="md:w-6/12 w-11/12 "
                  onClick={() => setOpen()}
                />
              </div>
            ) : (
              <div id="slideSource" className={`flex flex-1 mt-6 pb-4 overflow-scroll lg:overflow-auto ${focusInput !== false ? 'fade': ''}`}>
                <Search
                  placeholder="Search Customer Here"
                  name="search"
                  classContainer="w-10/12 mx-auto"
                  className="outline-none"
                  onChange={((e) => onUserInput(e))}
                  value={params.q}
                  visible={params.visible}
                  onSearch={params.onTextSearch}
                  onClose={() => params({visible: false})}
                  listKeyword={search?.data?.data}
                  onAutoCompleteClick={() => { 
                    setParams(prev => ({...prev, onSearchResult: false, visible: false, page: 1}))
                    setAutoCompleteClick()
                  }}
                  onKeyPress={e => onEnter(e)}
                  isLoading={isLoading}
                  // onBlur={() => setOpen()}
                />
              </div>
            )}
            {params.onSearchResult === true && (
              <div className="w-6/12 pl-9 pb-4 md:pl-16 md:w-3/12 lg:w-56 lg:pl-22 xl:pl-30 xl:w-64" style={{width: "fit-content"}}>
                <Filter style={{width: "fit-content"}} className="py-1 px-3 text-border-color border border-solid border-border-color rounded-3xl bg-white" placeholderName="Filter by Dealership:"
                  items={listFilterDealership?.data} options={params.dealership_code === null ? 'All Dealership' : params.dealership_name} imageIcon={expandMore} searchButton={true} homeFilter={true} onClick={(dealer) => dealershipFilterHandler(dealer)} onImageOpen={modalState?.dealershipFilterModal}
                />
              </div>
            )}
            <Modal onDealershipModal={true} title={"Filter by Dealership"} show={modalState?.dealershipFilterModal} closeModal={() => dealershipFilterHandler()}>
              <DealershipFilter data={listFilterDealership?.data} setDealershipCode={(data, name) => setParams(prev => ({ ...prev, dealership_code: data, dealership_name: name }))} closeModal={() => dealershipFilterHandler()} />
            </Modal>
            <CenterModal title={"Filter by Dealership"} show={modalState?.dealershipFilterModal} closeModal={() => dealershipFilterHandler()}>
              <DealershipFilter data={listFilterDealership?.data} setDealershipCode={(data, name) => setParams(prev => ({ ...prev, dealership_code: data, dealership_name: name }))} closeModal={() => dealershipFilterHandler()} />
            </CenterModal>
          </div>
          {isLoadingSearchResult && params.onSearchResult && (
            <div className="flex w-full py-10 justify-center">
              <CircularProgress color="inherit" size={20} />
            </div>
          )}
          <div
            className={onAutoCompleteClick ? 'h-screen overflow-scroll pb-56 bg-white lg:hidden block' : 'lg:hidden block'}
          >
            {onAutoCompleteClick && search?.data?.data?.map((result, index) => {
              const url = `/profile/${result.orag_id}`
              return(
                <div className="bg-white pl-6 pt-4 pb-4" style={{border: '1px solid #EBEEF2'}} key={index} onClick={() =>
                  setParams(prev => ({...prev, onSearchResult: false}))
                }>
                  <Link to={url}>
                    <div className="text-sm	font-semibold">
                      {result.fullname}
                    </div>
                    <div className="text-sm font-normal text-neutral-color">
                      {result.email}
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
          <div
            className={onAutoCompleteClick ? 'my-auto pt-8 pb-6 lg:grid grid-cols-3 grid-flow-row gap-4 w-10/12 mx-auto px-auto hidden' : 'lg:flex hidden'}
          >
            {onAutoCompleteClick && search?.data?.data?.map((result, index) => {
              const url = `/profile/${result.orag_id}`
              return(
                <div className="bg-white pl-6 pt-4 pb-4" style={{border: '1px solid #EBEEF2'}} key={index}>
                  <Link to={url}>
                    <div className="w-11/12">
                      <div className="text-sm	font-semibold">
                        {result.fullname}
                      </div>
                      <div className="text-sm font-normal text-neutral-color truncate">
                        {result.email}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
          {onAutoCompleteClick && (
            <div className="w-full hidden lg:inline-flex flex-1 justify-center items-center pb-28">
              <div className="mx-auto">
                <Pagination
                  count={total}
                  page={params.page}
                  onChange={(event, page) => setParams(prev => ({ ...prev, page: page }))}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Home
