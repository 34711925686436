import { useState } from 'react';
import { Link } from 'react-router-dom';

import MenuIcon from '../../assets/arrow drop_down.png';
import Logo from 'assets/orag-stk-rgb-3c.png'
import classNames from 'classnames';
import spikedCorner from 'assets/Beak.png';
import services from 'pages/Login/services';
function AdminHeader() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () =>  setIsOpen(!isOpen);

  const onOpen = classNames(
    {
      'transform rotate-180': !isOpen,
    }
  )

  const onLogout = () => {
    services.logout({
      success: (succ) => {
        console.log({succ});
      }, error: (err) => {
        console.log({err});
      }
    })
  }

  return (
    <header>
      <div className="w-full flex justify-between items-center px-3 py-2 bg-white">
        <div className="ml-3">
          <img src={Logo} alt="" className="w-36" />
        </div>

        <button className="font-custom font-normal text-xs flex items-center relative"  onClick={() => toggleOpen()}>
          Admin
          <img src={MenuIcon} alt="" className={classNames("w-6"), onOpen} />
          {isOpen && (
            <div className="text-border-color border border-solid border-filter-border rounded absolute z-10 bg-white right-1/4 top-full">
              <img src={spikedCorner} alt="" className="absolute -top-2 left-1/3" />
              <div className={classNames('py-2 px-4 flex')}>
                <div className="font-custom font-normal text-xs flex" onClick={() => onLogout()}>
                  logout
                </div>
              </div>
            </div>
          )}
        </button>
      </div>

    {/* if there are different in menu desktop and mobile this components needs to change */}
    </header>
  );
}

AdminHeader.propTypes = {};

export default AdminHeader;