import { useState } from 'react';
import { Link } from 'react-router-dom';

import MenuIcon from '../../assets/menu.png';
import MobileNav from './MobileNav';
import Logo from 'assets/orag-stk-rgb-3c (2)-02.png'

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(prev => !prev);
  }

  return (
    <header>
      <div className="w-full flex justify-between items-center px-3 py-2">
        <div className="ml-3">
          <Link to="/">
            <img src={Logo} className="w-36" alt="" />
          </Link>
        </div>
        <img src={MenuIcon} style={{ width: 33, height: 33 }} onClick={() => setIsOpen(true)} alt="" />
      </div>

    {/* if there are different in menu desktop and mobile this components needs to change */}
      <MobileNav isOpen={isOpen} toggleOpen={toggleOpen} />
    </header>
  );
}

Header.propTypes = {};

export default Header;