import React,{useState, useEffect} from 'react';
import classNames from 'classnames';

function DealershipModal(props) {
  const [dealershipSelected, setDealershipSelected] = useState(0);
  const [dealershipValue, setDealershipValue] = useState(null);

  const [listDealership, setListDealership] = useState(['All Dealership', 'OpenRoad Toyota Peach Arc', 'OpenRoad Audi Burnaby ']);
  
  const buttonClass = classNames(
    'border border-solid border-border-color rounded-3xl px-3 py-1 mr-2 font-semibold text-sm'
  )

  const filterReset = () => {
    setDealershipSelected(0)
    setDealershipValue(null);
    props?.setDealershipCode(null)
  }

  const detailResponse = React.useMemo(() => props?.data, [props?.data]);

  return (
    <div className="h-full lg:h-full">

      <div className="bg-white mb-1">
        <div className="w-11/12 lg:w-full pl-6 pt-8 pb-12 lg:py-9 border-b border-solid border-center-modal-border">
          {[{ id: null, dealership_name: 'All Dealership' }, ...detailResponse]?.map((dealer,index) => (
            <button className={dealershipSelected === (index) ? classNames(buttonClass, "mb-4 bg-border-color text-white") : classNames(buttonClass, "mb-4 text-border-color")} key={index} value={dealer?.dealership_name}
              onClick={() => {
                  setDealershipSelected(index);
                  setDealershipValue(dealer.dealership_name);
                  props?.setDealershipCode(dealer.dealership_code, dealer.dealership_name)
                }}>
                {dealer.dealership_name}
            </button>
          ))}
        </div>
      </div>

      <div className="bg-white pt-2 pb-48 lg:pb-0 px-3">
        <div className="lg:pl-6 flex">
          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-11 text-border-color mx-auto" onClick={() => filterReset()}>Reset Filter</button>

          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-16 bg-border-color text-white mx-auto" onClick={props.closeModal}>Show</button>
        </div>
      </div>
      
    </div>
  )
}

export default DealershipModal;
