import React,{useEffect, useState, useContext} from 'react';
import services from './services';
import Pagination from 'components/pagination';
import usePagination from 'utils/usePagination';
import {DateTime} from 'luxon';
import {CenterModalAdmin} from 'components/modal';
import AddNewAdmin from 'components/modal/AddNewAdmin';
import { AdminContext } from 'utils/context';
import DeleteLogo from 'assets/delete.png';
import DeleteAlert from 'assets/warning.png';
import Cookie from 'js-cookie'

function AdminView(props) {
  const { thisState, updateSync } = useContext(AdminContext);

  const [params, setParams] = useState({
    page: 1,
  })

  const id = props.id;

  const [detailResponse, setResponse] = useState({
    data: [],
    onSelectedUser: [],
  })

  const [total, setPagination] = usePagination();

  const [modalState, setModalState] = useState({
    editAdminModal: false,
    confirmDeleteModal: false,
    addNewAdminModal: false,
  })

  const editAdminHandler = (data) => {
    setResponse(prev => ({ ...prev, onSelectedUser: data }));
    setModalState(prev => ({
      ...prev,
      editAdminModal: !modalState.editAdminModal,
      // confirmDeleteModal: !modalState.confirmDeleteModal,
    }))
  }

  const addNewAdminHandler = (data) => {
    setModalState(prev => ({
      ...prev,
      addNewAdminModal: !modalState.addNewAdminModal
    }))
    if (thisState.sync === false) {
      services.getUser({user_type:'admin', is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(9, response.total);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    } else {
      services.getUser({user_type:'admin', sync: sync, is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
          updateSync(false);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    }
  }

  const confirmDeleteHandler = () => {
    setModalState(prev => ({
      ...prev,
      confirmDeleteModal: !modalState.confirmDeleteModal,
    }))
    if (thisState.sync === false) {
      services.getUser({user_type:'admin', is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    } else {
      services.getUser({user_type:'admin', sync: sync, is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
          updateSync(false);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    }
  }

  const [detailRole, setRole] = useState({data: []});
  const [detailStatus] = useState([{id: 1, name: "Active"}, {id: 0, name: "Inactive"}]);
  const [paramsModal, setParamsModal] = useState({
    role_id: 0,
    id: 0,
    status: 0,
    fullname: "",
    email: "",
  })

  useEffect(() => {
    services.getRole({
      success: (response) => {
        setRole(prev => ({ ...prev, data: response }));
      }, error: (err) => {
        console.log(err)
      }
    })
  }, [params, paramsModal, modalState])

  useEffect(() => {
    setParamsModal(prev => ({
      ...prev,
      role_id: detailResponse?.onSelectedUser?.role?.id,
      status: detailResponse?.onSelectedUser?.status,
      fullname: detailResponse?.onSelectedUser?.fullname,
      email: detailResponse?.onSelectedUser?.email,
      // status: detailResponse?.onSelectedUser?.status,
    }))
  }, [detailResponse?.onSelectedUser])

  const idd = detailResponse?.onSelectedUser?.id
  const role_id = paramsModal.role_id;
  const status = paramsModal.status;
  const fullname = paramsModal.fullname;
  const email = paramsModal.email;
  const onSaveChanges = () => { 
    return(
      editAdminHandler(),
      services.updateUser({
        id: idd,
        role_id,
        status,
        fullname,
        email
      }, (response, err) => {
        if (!err) {
          console.log(response);
        }
      })
    )
  }

  const onDeleteAdmin = () => {
    return(
      editAdminHandler(),
      confirmDeleteHandler(),
      services.updateUser({
        id: idd,
        role_id: null,
        status,
        fullname,
        email,
        is_assigned: 0,
      }, (response, err) => {
        if (!err) {
          console.log(response);
        }
      })
    )
  }

  const deleteAdminHandler = () => {
    setModalState(prev => ({
      ...prev,
      confirmDeleteModal: !modalState.confirmDeleteModal,
    }))
    if (thisState.sync === false) {
      services.getUser({user_type:'admin', is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    } else {
      services.getUser({user_type:'admin', sync: sync, is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
          updateSync(false);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    }
    // props.setConfirmDeleteModal()
  }

  const sync = 1;
  useEffect(() => {
    if (thisState.sync === false) {
      services.getUser({user_type:'admin', is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    } else {
      services.getUser({user_type:'admin', sync: sync, is_assigned: 1}, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
          updateSync(false);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    }
  }, [paramsModal, params, modalState]);

  function formatDate(data) {
    var dt = DateTime.fromISO(data);
    return `${dt?.c?.month}/${dt?.c?.day}/${dt?.c?.year}, ${dt?.c?.hour}:${dt?.c?.minute}`;
  }

  return (
    <div className="">
     <div className="ml-3 lg:ml-0 flex flex-col lg:flex-row mx-auto justify-between xl:mx-3">
        <div className="font-custom font-semibold text-2xl text-black-color pb-6 lg:ml-12 pt-6 ml-4">
          Admin
        </div>
        <div className="mr-10">
          <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-2 px-4 lg:my-6 my-2 bg-border-color text-white lg:mx-auto ml-4 lg:ml-0" onClick={() => addNewAdminHandler()}>Add New Admin</button>
        </div>
      </div>
      <div className="overflow-x-auto w-11/12 mx-auto h-full">
        <table className="bg-white w-full mx-auto">
          <thead>
            <tr className="font-custom font-semibold text-base border-b border-solid border-center-modal-border" style={{color: '#272833'}} height="50">
              <th className="text-left px-10 lg:pr-4">Full Name</th>
              <th className="text-left px-10 lg:px-4 xl:px-0">Email</th>
              <th className="text-left px-10 lg:px-4 xl:px-0">Last Modified</th>
              <th className="text-left px-10 lg:px-4 xl:px-0">Status</th>
            </tr>
          </thead>
          <tbody>
            {detailResponse?.data?.map((data) => {
              return(
                <tr className="border-b border-solid border-center-modal-border" key={data?.id}>
                  <td className="text-left px-10 lg:pr-4">
                    {data?.fullname}
                  </td>
                  <td className="text-left px-10 lg:px-4 xl:px-0">
                    {data?.email}
                  </td>
                  <td className="text-left px-10 lg:px-4 xl:px-0" >
                    {data?.updated_at !== null ? formatDate(data?.updated_at) : '-'}
                  </td>
                  <td className="px-10 lg:px-4 xl:px-0" width="50">
                    <div className={data?.status === 1 ? "text-center px-2 py-1 border border-solid border-green-badge text-green-badge font-custom font-semibold text-base rounded box-border" : "text-center p-1 border border-solid border-neutral-color text-neutral-color font-custom font-semibold text-base rounded box-border"}>
                      {data?.status === 1 ? "Active" : "Inactive"}
                    </div>
                  </td>
                  <td className="px-10 lg:px-4 xl:px-0" width="50">
                    <button onClick={() => editAdminHandler(data)} className="my-5 mx-5 text-center border border-solid border-border-color text-sm font-custom text-border-color font-semibold rounded py-2 px-3">
                      Edit
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <CenterModalAdmin adminModal={true} title={"Edit Admin"} show={modalState?.editAdminModal} closeModal={() => editAdminHandler()}>
          {modalState?.confirmDeleteModal === false && 
          <div className="lg:h-full mt-3">
            <div className="bg-white mb-1">
              <div className="flex flex-1 items-center py-6">
                <div className="w-11/12 py-3 md:pl-1 md:mr-20 lg:pr-24">
                  <div className="font-custom font-semibold text-base">
                    {paramsModal?.fullname}
                  </div>
                  <div className="font-custom font-normal text-sm">
                    {paramsModal?.email}
                  </div>
                </div>
                <div className="w-full">
                  <div className="font-custom font-semibold text-base text-black-color opacity-80">
                    Status
                  </div>
                  <div>
                    <select className="w-full py-3 md:mr-20 font-semibold rounded-lg bg-white font-custom text-neutral-color text-base border border-solid border-date-range px-32 pl-4" value={paramsModal.status}
                      onChange={(e) => {
                        setParamsModal(prev => ({ ...prev, status: Number(e.target.value)}));
                      }}
                    >
                      {detailStatus?.map((status) => {
                        return(
                          <option value={status.id}
                            key={status.id}
                            >
                            {status.name}
                          </option>
                        )}
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mx-1 border-b border-solid border-filter-border" />
            </div>

            <div className="flex justify-between bg-white pt-6">
              <div></div>
              <div className="flex">
                <button className="flex font-custom font-semibold text-base box-border border-2 border-solid border-error rounded-lg py-3 px-2 md:px-5 items-center bg-white text-error mx-auto mr-4" onClick={() => confirmDeleteHandler()}>
                  <img className="mr-2" src={DeleteLogo} alt="" />
                  Delete from Admin
                </button>
                <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-2 md:px-7 bg-border-color text-white mx-auto" onClick={onSaveChanges}>Save Change</button>
              </div>
            </div>
          </div>
          }
        </CenterModalAdmin>

        <CenterModalAdmin deleteRoleModal={true} adminModal={true} title={"Edit Admin"} show={modalState?.confirmDeleteModal} closeModal={() => {deleteAdminHandler(); editAdminHandler()}}>
          {modalState?.confirmDeleteModal === true && 
            <div className="h-full">
              <div className="flex pt-8 flex-col justify-center items-center">
                <img src={DeleteAlert} alt="" />
                <div className="font-custom font-semibold text-black-color text-base">
                  Are you sure to delete Admin?
                </div>
              </div>

              <div className="flex justify-between bg-white pt-6">
                <div></div>
                <div className="flex">
                  <button className="flex font-custom font-semibold text-base py-3 md:px-10 px-5 items-center bg-white text-border-color mx-auto mr-4" onClick={() => {deleteAdminHandler(); editAdminHandler()}}>  
                    Cancel
                  </button>

                  <button className="font-custom font-semibold text-base box-border border-2 border-solid border-error rounded-lg py-3 px-12 md:px-16 bg-error text-white mx-auto" onClick={onDeleteAdmin}>Delete Admin</button>
                </div>
              </div>
            </div>
          }
        </CenterModalAdmin>
        <CenterModalAdmin adminAddNewModal={true} adminModal={true} title={"Add New Admin"} show={modalState?.addNewAdminModal} closeModal={() => addNewAdminHandler()}>
          <AddNewAdmin id={detailResponse?.onSelectedUser?.id} data={detailResponse.onSelectedUser} closeModal={() => addNewAdminHandler()} />
        </CenterModalAdmin>

        <div className="flex items-center justify-center pt-8">
          <Pagination
            count={total}
            onChange={(event, page) => setParams(prev => ({ ...prev, page: page }))}
          />
        </div>
      </div>
    </div>
  )
}

export default AdminView
