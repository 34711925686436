import React,{useState, useEffect, useContext} from 'react'
import SearchButton from 'assets/search.png';
import classNames from 'classnames';
import services from './services';
import Pagination from 'components/pagination';
import usePagination from 'utils/usePagination';
import {CenterModalAdmin} from 'components/modal';
import EditUserModal from 'components/modal/EditUserModal';
import { DateTime } from "luxon";
import { AdminContext } from 'utils/context';
import 'components/modal/datePicker.css'
// import { string } from 'prop-types';
import Cookie from 'js-cookie'

function Salesperson(props) {

  const { thisState, updateSync } = useContext(AdminContext);

  const [params, setParams] = useState({
    q: null,
    text: null,
    page: 1,
    limit: 4,
    visible: false,
    onTextSearch: false,
    focus: false,
    onSelectRole: 0,
    onUpdated: false,
    status: null,
  })

  const [detailResponse, setResponse] = useState({
    data: [],
    onSelectedUser: [],
  })

  const [modalState, setModalState] = useState({
    editUserModal: false
  })

  const [total, setPagination] = usePagination();

  const onUserInput = (e) => {
    const textSearch = e.target.value;
    setParams(prev => ({ ...prev, q: textSearch}))

    if (params.q !== null) {
      setParams(prev => ({ ...prev, visible: true}));
    }

    if (textSearch === '') {
      setParams(prev => ({ ...prev, visible: false}))
    }

    clearTimeout(window.timeout);

    window.timeout = setTimeout(() => {
      setParams(prev => ({ ...prev, onTextSearch: textSearch}));
    }, 1000);
  }

  function onEnter(e) {
    if (e.key === 'Enter') {
      setParams(prev => ({ ...prev, visible: false}))
    }
  }

  const [chooseRole, setChooseRole] = useState({
    data: [],
  });

  const editUserHandler = (data) => {
    setResponse(prev => ({ ...prev, onSelectedUser: data }));
    setModalState({
      editUserModal: !modalState.editUserModal,
    })
  }

  const role_id = params.onSelectRole === 0 ? null : params.onSelectRole;

  const [detailRole, setRole] = useState({data: []});
  const [detailStatus] = useState([{id: 1, name: "Active"}, {id: 0, name: "Inactive"}]);
  const [paramsModal, setParamsModal] = useState({
    role_id: 0,
    id: 0,
    status: 0,
    fullname: "",
    email: "",
  })

  const sync = 1;
  useEffect(() => {
    if (thisState.sync === false && params.status !== 3) {
      services.getUser({
        role_id,
        page: params.page,
        q: params.q,
        status: params.status,
        user_type:'user'
      }, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(9, response.total);
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    } else if (thisState.sync === false && params.status === 3) {
      services.getUser({
        role_id,
        page: params.page,
        q: params.q,
        sync: sync,
        user_type:'user'
      }, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
          updateSync(false)
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    } else {
      services.getUser({
        role_id,
        page: params.page,
        q: params.q,
        status: params.status,
        sync: sync,
        user_type:'user'
      }, {
        success: (response) => {
          setResponse(prev => ({ ...prev, data: response.data }));
          setPagination(response.last_page);
          updateSync(false)
        }, error: (err) => {
          console.log(err)
          if (err.message === "Request failed with status code 401") {
            Cookie.remove('SID_OR');
            Cookie.remove('SID_OR_PERMISSIONS');
            Cookie.remove('SID_OR_USER_TYPE');
            window.location.replace('/login');
          }
        }
      });
    }
    services.getRole({
      success: (response) => {
        setChooseRole(prev => ({ ...prev, data: response.data }));
        setRole(prev => ({ ...prev, data: response }));
      }, error: (err) => {
        console.log(err)
      }
    })
  }, [params, paramsModal])

  function formatDate(data) {
    var dt = DateTime.fromISO(data);
    return `${dt?.c?.month}/${dt?.c?.day}/${dt?.c?.year}, ${dt?.c?.hour}:${dt?.c?.minute}`;
  }

  useEffect(() => {
    setParamsModal(prev => ({
      role_id: detailResponse.onSelectedUser?.role?.id,
      status: detailResponse.onSelectedUser?.status,
      fullname: detailResponse.onSelectedUser?.fullname,
      email: detailResponse.onSelectedUser?.email
    }))
  }, [detailResponse.onSelectedUser])

  const id = detailResponse.onSelectedUser?.id
  const rolerole_id = paramsModal.role_id;
  const status = paramsModal.status;
  const fullname = paramsModal.fullname;
  const email = paramsModal.email;
  const onSaveChanges = () => { 
    return(
      editUserHandler(),
      setParams(prev => ({ ...prev, onUpdated: !params.onUpdated})),
      services.updateUser({
        id,
        role_id:rolerole_id,
        status,
        fullname,
        email
      }, (response, err) => {
        if (!err) {
          console.log(response);
        }
      })
    )
  }
// console.log(detailResponse.onSelectedUser);
// console.log(params.onUpdated)
function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}

  return (
    <div className="">
      <div className="font-custom font-semibold text-2xl text-black-color pb-6 ml-12 lg:ml-10 xl:ml-14 pt-6">
        All User
      </div>
      <div className="w-11/12 mx-auto h-full bg-white">
        <div className="flex flex-col lg:flex-row ml-4 mr-0 justify-between mb-1">
          <div className="flex w-full lg:w-1/2 flex-col py-4">
            <div className="font-custom font-normal text-sm">Search</div>
            <div className="bg-white flex flex-1 relative">
              <input
                name="search"
                className={classNames('w-full rounded-lg font-semibold  bg-profile-bg font-custom text-neutral-color text-base lg:px-4 pl-4 pr-6 lg:pl-3 lg:pr-0 py-1 border border-gray-400 focus:border-primary-blue lg:mr-0')}
                onChange={(e) => onUserInput(e)}
                value={params.focus ? params.q : "Find User"}
                onKeyDown={e => onEnter(e)}
                onFocus={() => setParams(prev => ({ ...prev,
                  focus: true,
                  q: ""
                }))}
              />
              <button className={params.visible ? "absolute -right-3 top-1/2 bottom-1/2 lg:right-0 lg:bottom-1/2 lg:top-1/2 my-auto mr-1 w-10 h-10 flex items-center justify-center focus:outline-none" : "absolute -right-3 top-1/2 bottom-1/2 lg:right-0 lg:bottom-1/2 lg:top-1/2 my-auto mr-1 w-10 h-10 flex items-center justify-center focus:outline-none"} type="submit"
                onClick={() => setParams(prev => ({ ...prev, q: params.text}))}
              >
                <img src={SearchButton} alt="" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center mx-auto lg:w-1/4 w-full py-4">
            <div className="lg:pl-4 xl:pl-6 font-custom text-sm font-normal">Role</div>
            <select className="form-select px-5 lg:w-10/12 w-full py-3 lg:pl-4 mx-auto font-semibold rounded-lg bg-profile-bg font-custom text-neutral-color text-base" onChange={(e) => setParams(prev => ({...prev, onSelectRole: Number(e.target.value)}))}>
              {[{ id: 0, name: 'All',status: 1 }, ...chooseRole?.data].map((data,index) => { return(
                <option value={data?.id} key={index}>
                  {data?.name}
                </option>
              )})}
            </select>
          </div>
          <div className="flex flex-col justify-center lg:w-1/4 w-full py-4">
            <div className="font-custom text-sm font-normal">Status</div>
            <select className="form-select lg:w-10/12 w-full py-3 px-4 mr-20 font-semibold rounded-lg bg-profile-bg font-custom text-neutral-color text-base" onChange={(e) => setParams(prev => ({...prev, status: Number(e.target.value)}))}>
              {[{ id: 0, status: 3,name: 'All' }, { id: 0, status: 1,name: 'Active' }, { id: 3, status: 0,name: 'Inactive' }].map((data,index) => { return(
                <option value={data?.status} key={index}>
                  {data.name}
                </option>
              )})}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="bg-white w-full">
            <thead>
              <tr className="font-custom font-semibold text-base border-b border-solid border-center-modal-border" style={{color: '#272833'}} height="50">
                <th className="text-left px-10 lg:px-10">Full Name</th>
                <th className="text-left px-10 lg:px-4">Email</th>
                <th className="text-left px-10 lg:px-4">Role</th>
                <th className="text-left px-10 lg:px-11 xl:px-4">Last Modified</th>
                <th className="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {detailResponse?.data?.map((data) => {
                return(
                  <tr className="border-b border-solid border-center-modal-border" key={data.id}>
                    <td className="text-left px-10 lg:px-8">
                      {data?.fullname}
                    </td>
                    <td className="text-left px-10 lg:px-2">
                      {data?.email}
                    </td>
                    <td className="text-left px-10 lg:px-4">
                      {data?.status === 1 ? (data?.role?.name) : `-`}
                    </td>
                    <td className="text-left xl:w-52 lg:w-52">
                      {data?.updated_at !== null ? formatDate(data?.updated_at) : '-'}
                    </td>
                    <td className="px-10 lg:px-0" width="50">
                      <div className={data?.status === 1 ? "text-center px-2 py-1 border border-solid border-green-badge text-green-badge font-custom font-semibold text-base rounded box-border" : "text-center p-1 border border-solid border-neutral-color text-neutral-color font-custom font-semibold text-base rounded box-border"}>
                        {data?.status === 1 ? "Active" : "Inactive"}
                      </div>
                    </td>
                    <td className="px-10 lg:px-0" width="50">
                      <button onClick={() => editUserHandler(data)} className="my-5 mx-5 text-center border border-solid border-border-color text-sm font-custom text-border-color font-semibold rounded py-2 px-3">
                        Edit
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div></div>
      <CenterModalAdmin adminModal={true} title={"Edit User"} show={modalState?.editUserModal} closeModal={() => editUserHandler()}>
        <div className="h-full lg:h-full">

          <div className="bg-white mb-1">
            <div className="w-full pl-4 pt-8 pb-6">
              <div className="font-custom font-semibold text-base">
                {detailResponse?.onSelectedUser?.fullname}
              </div>
              <div className="font-custom font-normal text-sm">
                {detailResponse?.onSelectedUser?.email}
              </div>
            </div>
            <div className="flex flex-1 items-center px-4 pb-6 border-b border-solid border-filter-border">
              <div className="">
                <div className="font-custom font-semibold text-base text-black-color opacity-80 pb-2">
                  Role
                </div>
                <div>
                  <select className="py-3 pl-4 mr-6 font-semibold rounded-lg bg-white font-custom text-neutral-color text-base border border-solid border-date-range px-24" value={paramsModal.role_id}
                    onChange={(e) => {
                      setParamsModal(prev => ({ ...prev, role_id: Number(e.target.value)}));
                    }}
                  >
                    {detailRole?.data?.data?.map((user) => {
                      return(
                        <option value={user?.id} key={user?.id}>
                          {user?.name}
                        </option>
                      )}
                    )}
                  </select>
                </div>
              </div>
              <div className="">
                <div className="font-custom font-semibold text-base text-black-color opacity-80 pb-2">
                  Status
                </div>
                <div>
                  <select className="py-3 pl-4 font-semibold rounded-lg bg-white font-custom text-neutral-color text-base border border-solid border-date-range px-44" value={paramsModal.status}
                    onChange={(e) => {
                      setParamsModal(prev => ({ ...prev, status: Number(e.target.value)}));
                    }}
                  >
                    {detailStatus?.map((status) => {
                      return(
                        <option value={status?.id}
                          key={status?.id}
                          >
                          {status?.name}
                        </option>
                      )}
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between bg-white pt-6">
            <div></div>
            <div className="flex">
              <button className="font-custom font-semibold text-base box-border border-2 border-solid border-border-color rounded-lg py-3 px-7 bg-border-color text-white mx-auto mr-4" onClick={onSaveChanges}>Save Change</button>
            </div>
          </div>

          </div>
        {/* <EditUserModal id={role_id} data={detailResponse.onSelectedUser} closeModal={() => editUserHandler()} params={() => setParams(prev => ({ ...prev, onUpdated: !params.onUpdated}))} /> */}
      </CenterModalAdmin>
      <div className="flex items-center justify-center pt-8">
        <Pagination
          count={total}
          onChange={(event, page) => setParams(prev => ({ ...prev, page: page }))}
        />
      </div>
    </div>
  )
}

export default Salesperson
