import React from "react";
import { Redirect, Route } from "react-router-dom";
import token from 'utils/session';
import Cookie from 'js-cookie';

function ProtectedRoute({ component: Component, ...restOfProps },props) {
  const permissions = (Cookie?.get('SID_OR_PERMISSIONS'));
  const user_type = (Cookie?.get('SID_OR_USER_TYPE'));
  const stateCustomer = permissions?.indexOf(`view customer`);
  return (
    <Route
      {...restOfProps}
      render={(props) => { 
        return(
          ((props.match.path === "/admin" === true && user_type === "admin") && token) ? <Component {...props} />
          : ( (stateCustomer >= 0 ) && (props.match.path === "/" || props.match.path === "/profile/:id") && token )
          ? <Component {...props} /> : <Redirect to="/login" />
        )
      }}
    />
  );
}

export default ProtectedRoute;