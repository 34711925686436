import React,{useEffect} from 'react';
import windowsLogo from 'assets/Microsoft.png';
import Logo from 'assets/openroadsplash.png';
import LogoLarge from 'assets/orag-stk-rgb-3c.png';
// import CLogo from 'assets/C360.png';

function Login() {

  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      alert("Do you want to confirm Re-submission");
    });
  },[]);

  return (
    <div className="bg-login-background bg-cover bg-no-repeat lg:bg-none">
      <div className="min-h-screen flex flex-col relative" style={{backgroundImage: 'linear-gradient(180deg, rgba(82, 108, 139, 0.4) 0%, #2E496A 100%)'}}>
        <div className="absolute top-12 -left-14 lg:hidden">
          <img src={Logo} alt="" />
        </div>
        <div className="absolute bottom-36 left-6 lg:hidden">
          <div className="text-white text-3xl font-semibold font-custom">
            Welcome
          </div>
          <div className="pt-1 text-white font-custom font-normal text-base">
            Please login to continue
          </div>
          <div className="">
            <button className="mt-12 bg-white box-border border border-solid border-windows-login rounded-lg py-4 flex">
              <img src={windowsLogo} className="pl-16 ml-2 pr-2" alt="" />
              <a href={`${process.env.REACT_APP_API_URL}/oauth2/azure?redirectUri=${process.env.REACT_APP_API_URL}/login/azure`} className="text-windows-login font-medium text-base font-appleFonts pr-16 mr-2" >
                Sign in with Microsoft
              </a>
            </button>
          </div>
        </div>
      </div>
      <div
        className="absolute hidden z-20 lg:inline bottom-1/4 left-1/3 right-1/4 bg-white border border-solid border-center-modal-border box-border rounded-xl px-4 py-6"
        style={{
          opacity: 1,
        }}
      >
        <div className="flex border-b border-solid border-filter-border">
          <img src={LogoLarge} className="w-44 pb-6" alt="" />
          {/* <img src={CLogo} alt="" className="w-10 h-10" /> */}
        </div>
        <div
          className='flex-col flex justify-between pt-12 px-4 w-full bg-white'
        >
          <div className="text-black-color text-3xl font-semibold font-custom">
            Welcome
          </div>
          <div className="pt-1 text-black-color font-custom font-normal text-base border-b border-solid border-filter-border pb-12">
            Please login to continue
          </div>
        </div>
        <div className="">
          <div className="flex justify-center items-center pb-6">
            <a href={`${process.env.REACT_APP_API_URL}/oauth2/azure?redirectUri=${process.env.REACT_APP_API_URL}/login/azure`} className="mt-12 bg-white box-border border border-solid border-windows-login rounded-lg py-4 flex cursor-pointer">
              <img src={windowsLogo} className="pl-16 ml-2 pr-2" alt="" />
              <a className="text-windows-login font-medium text-base font-appleFonts pr-16 mr-2" >
                Sign in with Microsoft
              </a>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
