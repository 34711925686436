import axios from 'axios';
import endpoint from 'utils/endpoint';
import token from 'utils/session';

async function getDealership(callback,params) {
  const url = endpoint.dealership;
  const config = {
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url,config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

async function getDealershipOrag(callback,params) {
  const url = `${endpoint.dealership}?orag_id=${callback.id}`;
  const config = {
    method: 'get',
    callback,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url,config);
    await params.success(response.data);
  } catch (error) {
    await params.error(error);
  }
}

async function getUserSearch(params,callback) {
  const url = endpoint.search;
  const config = {
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  
  try {
    const response = await axios(url,config);
    await callback.success(response.data);
  } catch (error) {
    await callback.error(error);
  }
}

export default {
  getDealership,
  getUserSearch,
  getDealershipOrag,
};
