import axios from 'axios';
import endpoint from 'utils/endpoint';
import token from 'utils/session';

async function getProfileById(params, callback) {
  const url = `${endpoint.customer}/${params.id}`;
  const config = {
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getTransactionInsight(params, callback) {
  const url = `${endpoint.transactionInsight}?${params.orag_id}`;
  const config = {
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    await callback(response.data, null);
  } catch (error) {
    await callback(null, error);
  }
}

async function getTransactionType(callback) {
  const url = endpoint.transactionType;
  const config = {
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    await callback(response.data, null);
  } catch (error) {
    console.log(error);
  }
}

async function getTransactionActivity(params,callback) {
  const url = `${endpoint.transactionActivity}?orag_id=${params.id}`;
  const config = {
    method: 'get',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    // console.log(params);
    const response = await axios.get(url, config);
    await callback(response.data, null);
  } catch (error) {
    console.log(error);
  }
}

export default {
  getProfileById,
  getTransactionInsight,
  getTransactionType,
  getTransactionActivity,
};