import React,{useState} from 'react';
import spikedPhoto from 'assets/expand less.png';
import classNames from 'classnames';

function Cards(props) {
  const [isOpen, setIsOpen] = useState(true)
  const toggling = () => setIsOpen(!isOpen)
  const {children, childrenName} = props;
  const onOpen = classNames(
    {
      'transform rotate-180': !isOpen,
    }
  )
  
  return (
    <div className="bg-white mt-4 w-5/6 mx-auto flex flex-col flex-1 justify-between">
      <div className="w-5/6 mx-auto ">
        <div onClick={toggling} className="flex flex-row justify-between py-4" style={{borderBottom: '1px solid #EBEEF2'}}>
          <div className="font-semibold	text-base">
            {childrenName}
          </div>
          <img src={spikedPhoto} alt="" className={onOpen} />
        </div>
        {isOpen && (
          <div className="pt-4">
            <div className="">
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Cards
