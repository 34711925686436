import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import history from './history';
import Home from 'pages/Home';
import Profile from 'pages/Profile';
import Login from 'pages/Login';
import AzureCallback from 'pages/Login/AzureCallback';
import ProtectedRoute from 'components/protectedRoutes'

import {AdminProvider} from 'utils/context';
import Admin from 'pages/Admin';

import withSplashScreen from 'components/withSplashScreen';

function App() {
  let route = (
    <AdminProvider>
      <BrowserRouter>
        <Switch>
          <ProtectedRoute exact history={history} path="/" component={Home}></ProtectedRoute>
          <ProtectedRoute exact history={history} path="/profile/:id" component={Profile}></ProtectedRoute>
          <Route exact history={history} path="/login" component={Login}></Route>
          <Route exact history={history} path="/login/azure" component={AzureCallback}></Route>

          <ProtectedRoute exact history={history} path="/admin" component={Admin}></ProtectedRoute>
        </Switch>
      </BrowserRouter>
    </AdminProvider>
  );

  return (
    <>
      {route}
    </>
  );
}

export default withSplashScreen(App);
