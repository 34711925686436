export const api = process.env.REACT_APP_API_URL;
console.log("🚀 ~ file: endpoint.js ~ line 2 ~ api", api)

const endpoint = {

  //Role
  role: `${api}/role`,

  //Permission
  permission: `${api}/permission`,
  
  //Dealership
  dealership: `${api}/dealership`,

  //Transaction Type
  transactionType: `${api}/transaction/type`,

  //Transaction Insight
  transactionInsight: `${api}/transaction/insight`,

  //Transaction Activity
  transactionActivity: `${api}/transaction/activity`,

  //Customer
  customer: `${api}/customer`,

  //Search
  search: `${api}/customer/search`,

  //Token
  token: `${api}/oauth2/token`,

  //Logout
  logout: `${api}/auth/logout`,

  //User
  user: `${api}/user`,

  //Profile
  profile: `${api}/user/profile`
};

export default endpoint;